import * as React from "react";
import _get from "lodash.get";
import _find from "lodash.find";
import { useState } from "react";
import { Link, navigate } from "gatsby";
import routesConfig from "../../../routes/routesConfig";
import administrationRoutesConfig from "../../../routes/administrationRoutesConfig";
import Button from "../../Button";
import { info, primary } from "../../../utils/colors";
import "./Navbar.sass";
import withMultipleDropdowns from "../../HOC/withMultipleDropdowns";
import products from "../../../config/products";
import { inject, observer } from "mobx-react";
import { success as successAlert } from "../../../utils/notifications";
import { StaticImage } from "gatsby-plugin-image";

const Navbar = props => {
  const [toggle, setToggle] = useState(false);
  const { isAuthenticated, user } = props.auth;

  const logout = async () => {
    await props.auth.logout();
    successAlert("logged out successfully!");
    return await navigate(routesConfig.home.route);
  };
  const isProfileDropdownOpen = _get(_find(props.dataStatuses, { id: "profileDropdown" }), "opened");
  return (
    <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <button
          type="button"
          className="navbar-item p-0 has-background-white"
          onClick={async () => {
            await navigate(routesConfig.home.route);
          }}
        >
          <StaticImage
            src="../../../../static/images/boat-logo-240.jpg"
            alt="boat logo"
            loading="eager"
            placeholder="blurred"
            layout="constrained"
            width={52}
            height={52}
            formats={["AUTO", "WEBP", "AVIF"]}
          />
        </button>

        <button
          onClick={() => setToggle(!toggle)}
          type="button"
          className={`navbar-burger ${toggle ? "is-active" : ""}`}
          aria-label={toggle ? "Close navigation menu" : "Open navigation menu"}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div className={`navbar-menu ${toggle ? "is-active" : ""}`}>
        {props?.isAdmin ? (
          <div className="navbar-start">
            <Link className="navbar-item" to={administrationRoutesConfig.allReservations.route}>
              Всички резервации
            </Link>
            <Link className="navbar-item" to={administrationRoutesConfig.addReservation.route}>
              Добави резервации
            </Link>
          </div>
        ) : (
          <div className="navbar-start">
            <Link className="navbar-item" to={`${routesConfig?.mapOfNeaPeramos?.route}`}>
              Карта на Неа Перамос
            </Link>
            <Link className="navbar-item" to={routesConfig.boatInfo.route}>
              Информация за лодката
            </Link>
            {/*{products*/}
            {/*  .filter(p => !p.disabled)*/}
            {/*  .map(product => (*/}
            {/*    <Link key={product?.id} className="navbar-item" to={`${product?.link}`}>*/}
            {/*      {product?.title}*/}
            {/*    </Link>*/}
            {/*  ))}*/}
          </div>
        )}

        <div className="navbar-end">
          {isAuthenticated && (
            <div className="navbar-item has-dropdown is-hoverable">
              <button type="button" className="navbar-link is-size-4" onClick={() => props.toggle("profileDropdown")}>
                {_get(user, "email")}
              </button>
              <div className={`navbar-dropdown ${isProfileDropdownOpen ? "is-active" : "is-hidden-tablet is-hidden-mobile"}`}>
                <button
                  type="button"
                  className="navbar-item"
                  onClick={async () => {
                    await navigate(routesConfig.profile.route);
                  }}
                >
                  Моят профил
                </button>
                {/*<a className="navbar-item">Контакти</a>*/}
                <hr className="navbar-divider" />
                <button type="button" className="navbar-item" onClick={async () => await logout()}>
                  Изход
                </button>
              </div>
            </div>
          )}

          {!isAuthenticated && (
            <div className="navbar-item">
              <div className="buttons">
                <Button text="Регистрация" bgColor={primary} onClick={() => navigate(routesConfig.signUp.route)} />
                <Button text="Вход без парола" bgColor={info} onClick={() => navigate(routesConfig.loginWithoutPassword.route)} />
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
export default inject("workflow", "auth")(observer(withMultipleDropdowns(Navbar, [{ id: "profileDropdown" }])));
